import React, { useEffect } from 'react';
import Header from './Home/Header';
import Footer from './Home/Footer';
import './Gallery.css';

const Gallery = () => {
  useEffect(() => {
    const gallery = document.querySelector('#gallery');
    const getVal = (elem, style) => parseInt(window.getComputedStyle(elem).getPropertyValue(style));
    const getHeight = item => item.querySelector('.content').getBoundingClientRect().height;
    const resizeAll = () => {
      const altura = getVal(gallery, 'grid-auto-rows');
      const gap = getVal(gallery, 'grid-row-gap');
      gallery.querySelectorAll('.gallery-item').forEach(item => {
        const el = item;
        el.style.gridRowEnd = `span ${Math.ceil((getHeight(item) + gap) / (altura + gap))}`;
      });
    };
    gallery.querySelectorAll('img').forEach(item => {
      item.classList.add('byebye');
      if (item.complete) {
        console.log(item.src);
      } else {
        item.addEventListener('load', () => {
          const altura = getVal(gallery, 'grid-auto-rows');
          const gap = getVal(gallery, 'grid-row-gap');
          const gitem = item.parentElement.parentElement;
          gitem.style.gridRowEnd = `span ${Math.ceil((getHeight(gitem) + gap) / (altura + gap))}`;
          item.classList.remove('byebye');
        });
      }
    });
    window.addEventListener('resize', resizeAll);
    gallery.querySelectorAll('.gallery-item').forEach(item => {
      item.addEventListener('click', () => {
        item.classList.toggle('full');
      });
    });

    return () => {
      window.removeEventListener('resize', resizeAll);
      gallery.querySelectorAll('.gallery-item').forEach(item => {
        item.removeEventListener('click', () => {
          item.classList.toggle('full');
        });
      });
    };
  }, []); 

  return (
    <>
    <Header/>
    <br/><br/>
    <br/><br/>
    <br/><br/>
    <br/><br/>
    <div>
      {/* <h1>Masonry Gallery</h1> */}
      <div className="gallery" id="gallery">
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/1.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/2.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/3.jpg" alt=""/></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/4.jpg" alt=""/></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/5.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/6.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/7.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/8.jpg" alt=""/></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/9.jpg" alt=""/></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/10.jpg" alt=""/></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/11.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/12.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/13.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/14.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/15.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/16.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/17.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/18.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/19.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/20.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/21.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/22.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/23.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/24.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/25.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/26.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/27.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/28.jpg" alt="" /></div>
        </div>

        <div class="gallery-item">
            <div class="content"><img src="images/gallery/29.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/30.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/31.jpg" alt="" /></div>
        </div>

        <div class="gallery-item">
            <div class="content"><img src="images/gallery/32.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/33.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/34.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/35.jpg" alt="" /></div>
        </div>

        <div class="gallery-item">
            <div class="content"><img src="images/gallery/36.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/37.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/38.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/39.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/40.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/41.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/42.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/43.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/44.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/45.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/46.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/47.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/48.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/49.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/50.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/51.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/52.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/53.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/54.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/55.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/56.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/57.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/58.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/59.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/60.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/61.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/62.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/63.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/64.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/65.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/66.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/67.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/68.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/69.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/70.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/71.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/72.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/73.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/74.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/75.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/76.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/77.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/78.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/79.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/80.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/81.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/82.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/83.jpg" alt="" /></div>
        </div>
        <div class="gallery-item">
            <div class="content"><img src="images/gallery/84.jpg" alt="" /></div>
        </div>
      </div>
    </div>
    <br/>
    <br/>
    <br/>
    <Footer/>
    </>
  );
};

export default Gallery;
